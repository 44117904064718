import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniq } from 'lodash'
import React, { memo } from 'react'

export interface Props {
  title?: string
  description?: string
  prices?: Price[]
  image?: ImageProps
  technical_sheet?: string
  languageCode: string
  variant?: Variant
}

interface Price {
  format?: string
  cost?: string
}

export const Item = memo(function Item({
  title,
  description,
  image,
  prices,
  technical_sheet,
  languageCode,
  variant = 'default',
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Wrapper variant={variant}>
        <Left>{image ? <ImageBig {...image} variant={variant} /> : null}</Left>
        <Right variant={variant}>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {prices ? (
            <FadeInUp>
              <Formats>
                {uniq(prices).map((item, index) =>
                  item.format ? (
                    <Format key={index}>
                      {item.format} - {item.cost}
                    </Format>
                  ) : null,
                )}
              </Formats>
            </FadeInUp>
          ) : null}
          {technical_sheet ? (
            <FadeInUp>
              <StyledButton
                variant="dark"
                target="blank"
                file={technical_sheet}
                label={useVocabularyData('technical-sheet', languageCode)}
              />
            </FadeInUp>
          ) : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;
  padding-top: 12.5rem;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
    padding-top: 5rem;
  }

  @media (max-width: 992px) {
    padding-top: 0;
  }
`

const Wrapper = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          flex-direction: row-reverse;
        `
    }
  }}
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Right = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: 6.25rem;
  margin-left: 8.9375rem;
  z-index: 1;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          margin-left: 0;
          margin-right: 8.9375rem;

          @media (max-width: 1600px) {
            margin-left: 0;
            margin-right: 8.938vw;
          }

          @media (max-width: 1199px) {
            margin-left: 0;
            margin-right: 6.938vw;
          }

          @media (max-width: 992px) {
            margin-left: 0;
            margin-right: 0;
          }
        `
    }
  }}
`

const ImageBig = styled(Image)<ContainerProps>`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-left: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1199px) {
    margin-left: -1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-right: -1.25rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          margin-left: 0;
          margin-right: -8.9375rem;

          @media (max-width: 1600px) {
            margin-left: 0;
            margin-right: -8.9375vw;
          }

          @media (max-width: 1199px) {
            margin-left: 0;
            margin-right: -1.25rem;
          }

          @media (max-width: 992px) {
            margin-left: -1.25rem;
          }
        `
    }
  }}
`

const Title = styled.h2`
  ${styles.h2}

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Formats = styled.div``

const Format = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.label};
  font-size: 1.06rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
  margin-top: 2rem;
`

const Description = styled.div`
  ${styles.description}
  margin-top: 1.125rem;

  @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
    &::first-letter {
      -webkit-initial-letter: unset;
      initial-letter: unset;
      padding: 0;
      margin-top: 0;
    }
  }

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'inverted'
